<template>
  <div class="visitor-container signin">
    <div class="center">
      <img
        class="visitor-logo"
        src="~@/assets/logo.svg"
        alt="Clearwage"
        @click="$router.push('/')">
    </div>
    <div class="visitor-form">
      <header
        class="visitor-form-header"
        :class="{okta: isOktaMethod}"
        v-html="headerLabel" />
      <div class="visitor-form-content">
        <form @submit.prevent="next()">
          <div class="input-label" v-t="'account.email'"></div>
          <input
            ref="email"
            type="text"
            v-model="email"
            autocomplete="username"
            @input="resetSigninMethod()"
            :placeholder="$t('account.emailPlaceholder')"
            :class="{'input-error' : $v.email.$error}"
            :disabled="isLoading">

          <div class="signin-password-container" :class="{visible: isPasswordMethod}">
            <div class="input-label" v-t="'account.password'"></div>
            <input
              ref="password"
              type="password"
              v-model="password"
              autocomplete="current-password"
              :placeholder="$t('account.passwordPlaceholder')"
              :class="{'input-error' : $v.password && $v.password.$error}"
              :disabled="isLoading">
            <div class="password-forget">
              <span class="hover-link" @click="resetPassword()" v-t="'account.passwordReset.forgotYourPassword'"></span>
            </div>
          </div>
          <button class="primary" :disabled="isLoading || this.isOktaMethod">{{ signinButtonText }}</button>
        </form>
        <resizable-panel :observer="errorMessage">
          <div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
        </resizable-panel>
      </div>
    </div>
  </div>
</template>
<script>
import { loadRedirectRouteOnce } from '@/utils/route'
import ResizablePanel from '@components/commons/ResizablePanel.vue'
import { email as emailFormat, required } from 'vuelidate/lib/validators'

const REDIRECTION_DELAY = 2000 // Let the customer read the redirection banner
const CW_SIGNIN_EMAIL = 'CW_SIGNIN_EMAIL'

export default {
  data() {
    return {
      email: '',
      errorMessage: '',
      isLoading: false,
      password: '',
      signinMethod: null
    }
  },
  methods: {
    next() {
      if (!this.signinMethod) {
        this.$v.$touch()

        if (!this.$v.$error) {
          this.getSigninMethod()
        }
      }
      else if (this.isPasswordMethod) {
        this.signIn()
      }
    },
    getSigninMethod() {
      this.errorMessage = null
      this.isLoading = true
      this.$store.dispatch('account/getSigninMethod', { email: this.email })
        .then(signinMethod => {
          this.signinMethod = signinMethod
          this.isLoading = false
          this.$nextTick(() => {
            if (this.isPasswordMethod && this.$refs.password) {
              this.$refs.password.focus()
            }
            else if (this.isDomainAuthMethod) {
              this.signInWithDomainAuth()
            }
            else if (this.isOktaMethod) {
              this.signInWithOkta()
            }
          })
        })
        .catch(error => {
          this.isLoading = false
          this.errorMessage = error
        })
    },
    signIn() {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.errorMessage = null
        this.isLoading = true
        this.$store.dispatch('account/signIn', { email: this.email, password: this.password })
          .then(response => {
            this.storeSigninEmail()
            this.redirectToApp()
          })
          .catch(error => {
            this.errorMessage = error
            this.isLoading = false
          })
      }
    },
    redirectToApp() {
      const redirectRoute = loadRedirectRouteOnce()
      this.$router.push(redirectRoute || '/', () => {})
    },
    resetSigninMethod() {
      if (!this.isLoading) {
        this.signinMethod = null
      }
    },
    signInWithDomainAuth() {
      this.resetPassword(true)
    },
    signInWithOkta() {
      setTimeout(() => {
        this.storeSigninEmail()
        this.$store.dispatch('okta/loginWithRedirect', this.signinMethod)
      }, REDIRECTION_DELAY)
    },
    async checkOKTAConnectStatus() {
      if (this.$route.params.isOktaAuth) {
        // OktaImplicitCallback will set isOktaAuth to true if successful
        this.signinMethod = { identityProvider: 'okta' }
        this.isLoading = true
        const credentials = await this.$store.dispatch('okta/getLoginCredentials')
        if (credentials) {
          setTimeout(() => {
            this.$store.dispatch('account/signInWithOpenIDConnect', credentials)
              .then(this.redirectToApp)
              .catch(error => {
                this.signinMethod = null
                this.errorMessage = error
                this.isLoading = false
              })
          }, REDIRECTION_DELAY)
        }
        else {
          this.signinMethod = null
          this.isLoading = false
        }
      }
      else if (this.$route.query.iss) {
        // Okta dashboard will pass iss parameter in simplified flow
        this.signinMethod = { identityProvider: 'okta' }
        this.isLoading = true
        const issuer = this.$route.query.iss
        this.$store.dispatch('okta/loginWithIssuer', issuer)
      }
      else if (this.$route.params.errorMessage) {
        // OktaImplicitCallback will set errorMessage on error
        const error = this.$route.params.errorMessage
        this.errorMessage = this.$t('account.signin.oktaError', { error })
      }
    },
    resetPassword(applyRequest = false) {
      this.storeSigninEmail()
      this.$router.push({
        name: 'passwordRequest',
        params: {
          email: this.$v.email.$invalid ? null : this.email,
          apply: applyRequest
        }
      })
    },
    storeSigninEmail() {
      sessionStorage.setItem(CW_SIGNIN_EMAIL, this.email)
    },
    restoreSigninEmail() {
      if (sessionStorage.getItem(CW_SIGNIN_EMAIL)) {
        this.email = sessionStorage.getItem(CW_SIGNIN_EMAIL)
      }
    }
  },
  computed: {
    isPasswordMethod() {
      return this.signinMethod && this.signinMethod.identityProvider === 'clearwage'
    },
    isDomainAuthMethod() {
      return this.signinMethod && this.signinMethod.identityProvider === 'clearwage_domain_auth'
    },
    isOktaMethod() {
      return this.signinMethod && this.signinMethod.identityProvider === 'okta'
    },
    signinButtonText() {
      if (this.isPasswordMethod) {
        return this.$t(this.isLoading ? 'account.pleaseWait' : 'account.signin.action')
      }
      else {
        return this.$t(this.isLoading || this.isOktaMethod ? 'account.pleaseWait' : 'common.next')
      }
    },
    headerLabel() {
      if (this.isOktaMethod && this.isLoading) {
        return this.$t('account.signingIn')
      }
      else if (this.isOktaMethod) {
        return this.$t('account.signin.thirdPartyRedirect')
      }
      else if (this.isPasswordMethod && this.isLoading) {
        return this.$t('account.signingIn')
      }
      else if (this.isPasswordMethod) {
        return this.$t('account.signin.pleaseEnterPassword')
      }
      else {
        return this.$t('account.signin.pleaseEnterEmail')
      }
    }
  },
  created() {
    this.restoreSigninEmail()
    this.checkOKTAConnectStatus()
  },
  mounted() {
    this.$refs.email.focus()
  },
  validations() {
    const email = {
      required,
      emailFormat
    }
    const password = {
      required
    }
    return this.isPasswordMethod ? { email, password } : { email }
  },
  components: {
    ResizablePanel
  }
}
</script>

<style lang="scss" scoped>
@import "./src/styles/visitor.scss";

.signin-password-container {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 300ms, opacity 300ms;

  &.visible {
    max-height: 110px;
    opacity: 1;
  }
}

.password-forget {
  width: 100%;
  text-align: right;
  margin: 5px 0 20px 0;
  padding-right: 10px;
  color: $light-text-color;
}

$okta-color: desaturate(lighten(#127bba, 6), 12);

.primary.okta {
  background-color: $okta-color;

  &:hover {
    background-color: lighten($okta-color, 10);
  }

  &:focus {
    box-shadow: inset 0px 0px 0px 2px lighten($okta-color, 10);
  }

  img {
    vertical-align: -0.5px;
    margin-left: 2px;
  }
}
</style>
